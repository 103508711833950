import React, { useMemo, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Box,
  Button,
  Divider,
} from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HistoryIcon from '@mui/icons-material/History';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../assets/whitelogo.png'; // Adjust the path as necessary

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // Get user session from localStorage
  const userSession = useMemo(() => JSON.parse(localStorage.getItem('userSession')), []);

  // Toggle drawer visibility
  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
  };

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem('userSession');
    localStorage.removeItem('isAuthenticated');
    navigate('/login'); // Redirect to login page after logout
  };

  // Define menu items based on the user's role
  const menuItems = useMemo(() => {
    const baseMenu = [
      {
        text: 'Dashboard',
        icon: <DashboardIcon />,
        path: '/dashboard',
      },
      {
        text: 'New Booking',
        icon: <AddCircleIcon />,
        path: '/dashboard/new-booking',
      },
      {
        text: 'All Booking',
        icon: <HistoryIcon />,
        path: '/dashboard/history',
      },
    ];

    if (userSession?.user_role === 'dev') {
      return [
        ...baseMenu,
        {
          text: 'Manage  User',
          icon: <PersonAddIcon />,
          path: '/dashboard/removeuser',
        },
        {
          text: 'Manage Services',
          icon: <EditIcon />,
          path: '/dashboard/addservices',
        },
      ];
    }

    return baseMenu;
  }, [userSession?.user_role]);

  return (
    <Box>
      {/* Mobile Menu Button */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer}
        sx={{
          display: { xs: 'block', sm: 'none' },
          position: 'fixed',
          top: 10,
          left: 10,
          zIndex: 1301,
        }}
      >
        <MenuIcon />
      </IconButton>

      {/* Sidebar Drawer */}
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={toggleDrawer}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            backgroundColor: '#1a237e',
            color: '#fff',
          },
        }}
      >
        <SidebarContent
          onLogout={handleLogout}
          toggleDrawer={toggleDrawer}
          menuItems={menuItems}
          userSession={userSession}
        />
      </Drawer>

      {/* Persistent Sidebar for larger screens */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            backgroundColor: '#1a237e',
            color: '#fff',
          },
        }}
        open
      >
        <SidebarContent
          onLogout={handleLogout}
          menuItems={menuItems}
          userSession={userSession}
        />
      </Drawer>
    </Box>
  );
};

const SidebarContent = ({ onLogout, toggleDrawer, menuItems, userSession }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2,
      }}
    >
      <img src={logo} alt="Dashboard Logo" style={{ width: '150px', marginBottom: '20px' }} />
      <Typography variant="h6" align="center">
        Welcome, {userSession?.name || 'User'}
      </Typography>
    </Box>

    <List sx={{ flexGrow: 1 }}>
      {menuItems.map((item) => (
        <NavLink
          to={item.path}
          key={item.text}
          style={({ isActive }) => ({
            textDecoration: 'none',
            color: isActive ? '#ffcc00' : '#fff',
          })}
          onClick={toggleDrawer} // Close the drawer on mobile after selecting an item
        >
          <ListItem button sx={{ '&:hover': { backgroundColor: '#3949ab' } }}>
            <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        </NavLink>
      ))}
    </List>

    <Divider sx={{ my: 1, backgroundColor: '#3949ab' }} />

    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<LogoutIcon />}
        onClick={onLogout}
        fullWidth
      >
        Logout
      </Button>
    </Box>
  </Box>
);

export default Sidebar;
