import React, { useEffect, useState } from 'react';
import {
  Toolbar,
  CssBaseline,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import DashboardContent from '../components/DashboardContent';
// import BookingList from '../components/BookingList';
import AddBooking from '../components/NewBooking';
import AddUser from '../components/AddUser';
import RemoveUser from '../components/RemoveUser';
import History from '../components/History';
import Scorecard from '../components/Scorecard';
import ServicesComponent from '../components/Servicescomponent'; 

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const userSession = JSON.parse(localStorage.getItem('userSession')) || {};

    if (!userSession.name) {
      console.warn('No user name found in session data');
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: '#f5f5f5' }}>
      <CssBaseline />
      

      {/* Sidebar */}
      <Sidebar isOpen={isSidebarOpen} onToggle={toggleSidebar} />

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: isSmallScreen ? 0 : '240px',
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        {isSmallScreen && <Toolbar />}

        {/* Routes */}
        <Routes>
          <Route path="/" element={<DashboardContent />} />
          {/* <Route path="booking" element={<BookingList />} /> */}
          <Route path="new-booking" element={<AddBooking />} />
          <Route path="history" element={<History />} />
          <Route path="adduser" element={<AddUser />} />
          <Route path="removeuser" element={<RemoveUser />} />
          <Route path="scorecard" element={<Scorecard />} />
          <Route path="addservices" element={<ServicesComponent/>} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Dashboard;