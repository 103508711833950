  // Function to convert JSON to CSV
  export const jsonToCSV=(jsonData)=>{
    const header = Object.keys(jsonData[0]);
    const rows = jsonData.map(row => header.map(fieldName => JSON.stringify(row[fieldName])).join(','));
    return [header.join(','), ...rows].join('\n');
  }
 // Optional: Download the CSV file
 export const  downloadCSV=(csvContent, fileName = 'data.csv')=>{
  const link = document.createElement('a');
  link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
  link.target = '_blank';
  link.download = fileName;
  link.click();
}